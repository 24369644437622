import { axiosInstance } from "services";
import toastr from "toastr";
import { authStorage } from "./authStorage";
import { firebase } from "./firebase";
import { clearGleapIndentity } from "./gleap";
import { clearImpersonation } from "./impersonation";
import axios from "axios";
import { AmptitudeEvents } from "@services/amptitude_events";
import { LOGIN_CLICKED, WRONG_PASSWORD_ENTERED } from "./amptitude_contants";
let errMessage =
  "Email or password entered is incorrect. Please review and try again.";

export const signinWithFirebaseCustomToken = async (customToken) => {
  try {
    let userCredential = await firebase
      .auth()
      .signInWithCustomToken(customToken);
    const token = await userCredential.user.getIdToken();
    authStorage.token = token;
    authStorage.customToken = customToken;

    const accessToken = localStorage.getItem("amityAccessToken");

    if (accessToken) {
      const communityId = "63aec51a1f8ad84ae99ba3da";

      const communityResponse = await axios.post(
        `https://api.eu.amity.co/api/v3/communities/${communityId}/join`,
        {},
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      // if (communityResponse) {
      //   return communityResponse.data;
      // }
    }
    return token;
  } catch (err) {
    console.error(err);
  }
};

export const checkPhone = async ({ phoneNumber, country }) =>
  await axiosInstance.post("/users/check-phone-number", {
    phoneNumber,
    countryAlias: country.countryCode,
  });

export const checkEmail = async (email) =>
  await axiosInstance.post("/users/check-email-exist", {
    email,
  });

export const getMe = async (token) =>
  await axiosInstance.get("/me", {
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {},
  });

export const signup = async (data) => {
  try {
    const { confirmPassword, agree, country, ...rest } = data;
    const { data: responseData, status } = await axiosInstance.post(
      "/auth/sign-up",
      {
        ...rest,
        countryCode: country.geonameId,
        countryName: country.countryName,
        countryAlias: country.countryCode,
      }
    );
    if (status === 200) {
      await signinWithFirebaseCustomToken(responseData?.customToken);
      return { user: responseData?.user, isSuccess: true };
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message);
    const { code } = error || {};
    return {
      errorCode: code,
    };
  }
};

export const updateUserProfile = async (data) => {
  try {
    const { confirmPassword, agree, country, ...rest } = data;
    await signinWithFirebaseCustomToken(authStorage.customToken);

    const { data: responseData, status } = await axiosInstance.put(
      "/auth/profile",
      {
        ...rest,
        countryCode: country.geonameId,
        countryName: country.countryName,
        countryAlias: country.countryCode,
      }
    );

    if (status === 200) {
      return { user: responseData, isSuccess: true };
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message);
    const { code } = error || {};
    return {
      errorCode: code,
    };
  }
};

// data only include {token}
export const verifyPhoneNumber = async (data) => {
  try {
    const {
      data: { user, customToken },
    } = await axiosInstance.post("/auth/verify-phone-number", data);
    let token = await signinWithFirebaseCustomToken(customToken);
    return { user, token };
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
    throw err.message;
  }
};

export const signin = async (email, password) => {
  try {
    const {
      data: { user, customToken },
    } = await axiosInstance.post("/auth/login", { email, password });

    const amitySignIn = await axios.get(
      `https://api.eu.amity.co/api/v3/authentication/token?userId=${email}`,
      {
        headers: {
          "x-server-key":
            "fb5d6037c0457e85b8e3f15ba610387626faf1031e6552a7628cb81c61e2be75e1dc897156513bd48c89288dc851060e40585144f3135801f8c34c63ae",
        },
      }
    );

    const amityRegisterSession = await axios.post(
      "https://api.eu.amity.co/api/v4/sessions",
      {
        userId: email,
        deviceId: email,
        displayName: email,
        authToken: amitySignIn.data,
        custom: email,
      },
      {
        headers: {
          "x-api-key": "b0efef093d89a2604c318e1e030f138bd10c89e2ba343e2d",
        },
      }
    );
    localStorage.setItem(
      "amityAccessToken",
      amityRegisterSession?.data?.accessToken
    );

    localStorage.removeItem("impersonation");
    localStorage.removeItem("impersonationAdminEmail");
    await signinWithFirebaseCustomToken(customToken);
    if (user?.id) {
      await AmptitudeEvents("Login", user?.id, LOGIN_CLICKED);
    }

    return user;
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
    await AmptitudeEvents("Login", email, WRONG_PASSWORD_ENTERED);
    throw err.message;
  }
};

export const AmityRegister = async (email) => {
  try {
    const amitySignIn = await axios.get(
      `https://api.eu.amity.co/api/v3/authentication/token?userId=${email}`,
      {
        headers: {
          "x-server-key":
            "fb5d6037c0457e85b8e3f15ba610387626faf1031e6552a7628cb81c61e2be75e1dc897156513bd48c89288dc851060e40585144f3135801f8c34c63ae",
        },
      }
    );
    const amityRegisterSession = await axios.post(
      "https://api.eu.amity.co/api/v4/sessions",
      {
        userId: email,
        deviceId: email,
        displayName: email,
        authToken: amitySignIn.data,
        custom: email,
      },
      {
        headers: {
          "x-api-key": "b0efef093d89a2604c318e1e030f138bd10c89e2ba343e2d",
        },
      }
    );
    localStorage.setItem(
      "amityAccessToken",
      amityRegisterSession?.data?.accessToken
    );
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
    throw err.message;
  }
};
export const signout = async () => {
  try {
    // clearGleapIndentity();
    clearImpersonation();
    return await firebase.auth().signOut();
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
    throw err.message;
  }
};

export const setUpRecaptcha = ({ buttonId }) => {
  window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(buttonId, {
    size: "invisible",
  });
  window.recaptchaVerifier
    .render()
    .then((widgetId) => (window.recaptchaWidgetId = widgetId));
  return window.recaptchaVerifier;
};

export const sendOTP = ({ phone, ifSuccess, ifFailed }) => {
  const appVerifier = window.recaptchaVerifier;
  firebase
    .auth()
    .signInWithPhoneNumber(phone, appVerifier)
    .then((confirmationResult) => {
      window.confirmationResult = confirmationResult;
      ifSuccess(confirmationResult);
    })
    .catch((err) => {
      grecaptcha.reset(window.recaptchaWidgetId);
      ifFailed(err);
    });
};

export const VERIFY_TYPE = {
  reset_password: "reset-password",
  unblock: "unblock",
};

export const verifyOTP = async ({
  code,
  sessionInfo,
  type = VERIFY_TYPE.reset_password,
}) => {
  try {
    const { data, status } = await axiosInstance.post("/auth/verify-otp", {
      code,
      sessionInfo,
      type,
    });
    if (status === 200) return data;
    return null;
  } catch (error) {
    toastr.error(error?.message || "Wrong OTP");
  }
};

export const renewPassword = async (password, token) => {
  try {
    if (!password || !token) return;
    const { data, status } = await axiosInstance.post("auth/renewal-password", {
      password,
      token,
    });
    if (status === 200) return data;
    return null;
  } catch (error) {
    console.error(error);
    throw new Error("Something went wrong!");
  }
};

export const updateInfo = async (data) => {
  try {
    const newData = {
      about: data.about,
      jobTitle: data.jobTitle,
      userLocation:
        data.userLocation.country !== "" && data.userLocation.city !== ""
          ? data.userLocation
          : null,
      socialMedia: data.socialMedia,
    };
    return await axiosInstance.post("/users/update-info", newData);
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
    throw err.message;
  }
};

export const sendVerificationEmail = async (email) => {
  try {
    const response = await axiosInstance.post("/auth/send-email-verification", {
      email,
    });

    if (response?.status === 204) {
      return {
        isSuccess: true,
      };
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error.errors[0]?.message);
    return {
      errorCode: error?.code,
    };
  }
};

export const emailVerification = async (token) => {
  try {
    const response = await axiosInstance.post("/auth/email-verification", {
      token,
    });

    if (response?.status === 204 || response?.status === 200) {
      return {
        isSuccess: true,
      };
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error.errors[0]?.message);
  }
};

export const registerAmityInDashboard = async () => {
  try {
    const amitySignIn = await axios.get(
      `https://api.eu.amity.co/api/v3/authentication/token?userId=demo.user@frupro.com`,
      {
        headers: {
          "x-server-key":
            "fb5d6037c0457e85b8e3f15ba610387626faf1031e6552a7628cb81c61e2be75e1dc897156513bd48c89288dc851060e40585144f3135801f8c34c63ae",
        },
      }
    );

    const amityRegisterSession = await axios.post(
      "https://api.eu.amity.co/api/v4/sessions",
      {
        userId: "demo.user@frupro.com",
        deviceId: "demo.user@frupro.com",
        displayName: "demo.user@frupro.com",
        authToken: amitySignIn.data,
        custom: "demo.user@frupro.com",
      },
      {
        headers: {
          "x-api-key": "b0efef093d89a2604c318e1e030f138bd10c89e2ba343e2d",
        },
      }
    );
    localStorage.setItem(
      "amityAccessToken",
      amityRegisterSession?.data?.accessToken
    );
    return amityRegisterSession?.data?.accessToken;
  } catch (error) {
    console?.error(error);
  }
};

export const createNewPassword = async (data) => {
  try {
    const response = await axiosInstance.post("auth/change-password-by-token", {
      ...data,
    });

    if (response?.status === 204 || response?.status === 200) {
      toastr.success("Password successfully created,you can use FruPro.");
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(
      error?.response?.data?.message ||
        error.message ||
        error.errors[0]?.message
    );
  }
};

export const verifyToken = async (token) => {
  try {
    const response = await axiosInstance.post("auth/verify-token", {
      token: token,
    });

    if (response?.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(
      error?.response?.data?.message ||
        error.message ||
        error.errors[0]?.message
    );
  }
};
